import { TYPESENSE_DOCUMENT_HIT, TYPESENSE_RESPONSE_DTO } from '../utils/constants'
import { css, cssBatch, find } from '../utils/dom'
import showToastify from '../utils/toastify'
import axios from 'axios'

function generateTextFragmentLink(baseUrl, selectedText) {
	if (!baseUrl || !selectedText) {
		throw new Error('Base URL and selected text are required')
	}

	// Encode the selected text to ensure it works in URLs
	const encodedText = encodeURIComponent(selectedText)

	// Construct the URL with the text fragment directive
	const fragmentLink = `${baseUrl}#:~:text=${encodedText}`

	return fragmentLink
}

const origin =
	window.location.origin
	//import.meta.env === 'production' ? window.location.origin : 'https://instituto-djanira.dokku-sites.novadata.com.br'
	//import.meta.env === 'production' ? window.location.origin : 'http://127.0.0.1:8000'

export default function () {
	const url = `${origin}/typesense_search/`
	const urlArtistaHighlight = (text) => generateTextFragmentLink(`${origin}/a_artista/`, text)
	const urlNoticias = (slug) => `${origin}/novidades/${slug}`

	const $ = {
		field: find('[js-field-busca]'),
		input: find('#search-input'),
		closeModal: find('#search-close'),
		openModal: find('#search-open'),
		searchSectionActive: find('#search-active'),
		searchSectionInactive: find('#search-inactive'),
		empty: find('#search-empty'),
		biografia: {
			section: find('#search-biografia'),
			hits: find('#search-biografia .Hits'),
			qnt: find('#search-biografia .qnt'),
		},
		cronologia: {
			section: find('#search-cronologia'),
			hits: find('#search-cronologia .Hits'),
			qnt: find('#search-cronologia .qnt'),
		},
		noticias: {
			section: find('#search-noticias'),
			hits: find('#search-noticias .Hits'),
			qnt: find('#search-noticias .qnt'),
		},
	}

	const classHitArtista = 'text-primary-04 text-ellipsis text-[1.5rem] py-[2rem] border-solid border-b-[1px] border-primary-04 font-bold'

	const hitTemplateArtista = (hit) => {

		const contents = hit.highlight?.contents
		const contentsArray = Array.isArray(contents) ? contents : [contents];



		return contentsArray.map(
				({ snippet, matched_tokens }) =>
					`<a class='${classHitArtista} pesquisa-item' href='${urlArtistaHighlight(
						snippet.replaceAll('<mark>', '').replaceAll('</mark>', '')
					)}'> ${snippet}</a>`
			)

		
		/*
		hit.highlight.contents
		.map(
				({ snippet, matched_tokens }) =>
					`<a class='${classHitArtista}' href='${urlArtistaHighlight(
						snippet.replaceAll('<mark>', '').replaceAll('</mark>', '')
					)}'> ${snippet}</a>`
			)
			.join()*/
		}

	const hitTemplateCronologia = (hit) =>{
		const snippet = hit.highlight?.custom_descricao?.snippet
		if (!snippet) return ""
		return `<a class='${classHitArtista} pesquisa-item' href='${urlArtistaHighlight(
						snippet.replaceAll('<mark>', '').replaceAll('</mark>', '')
					)}'> ${snippet}</a>`
	}


	
	/** @param {TYPESENSE_DOCUMENT_HIT} hit */
	const hitTemplateNoticias = ({ document, highlight }) => {

		return `<a class='${classHitArtista} pesquisa-item' href='${urlNoticias(document.slug)}'> ${highlight.titulo?.snippet ? highlight.titulo.snippet : highlight.resumo.snippet}</a>`
	}

	/*
	const hitTemplateNoticias = ({ document, highlight }) => {

		return (`
			<a href="${urlNoticias(document.slug)}" class="group flex h-auto w-1/3 max-w-[566px] flex-col md:w-full">
				<div class="relative mb-24 h-[244px] w-full overflow-hidden">
					<img  src="${document.thumbnail}" alt="${document.titulo}" class="absolute inset-0 m-auto size-full object-cover transition-transform hover:scale-105" />
					</div>
					<span class="text-[16px] text-[#4E4A8D]" >${document.categoria}</span>
					<p class="!font- mt-8 !font-bold text-[#4E4A8D] text-headline-20 group-hover:text-primary-02">${
						highlight.titulo?.snippet ? highlight.titulo.snippet : highlight.resumo.snippet
					}</p>
			</a>
		`);
	}*/

	function setQnts({ biografia, cronologia, novidades }) {
		$.biografia.qnt.innerText = biografia.found
		$.cronologia.qnt.innerText = cronologia.found
		$.noticias.qnt.innerText = novidades.found
	}

	/** @param {TYPESENSE_RESPONSE_DTO} data  */
	function setHits(data) {
		const { biografia, cronologia, novidades } = data
		$.biografia.hits.innerHTML = biografia.hits?.map(hitTemplateArtista).join('') || ''
		$.cronologia.hits.innerHTML = cronologia.hits?.map(hitTemplateCronologia).join('') || ''
		$.noticias.hits.innerHTML = novidades.hits?.map(hitTemplateNoticias).join('') || ''


		const itensCronologia = document.querySelectorAll(".pesquisa-item");
		itensCronologia.forEach((item)=>{
			item.addEventListener('click', (e)=>{
				window.location.href = e.target.href;
				window.location.reload();
			})
		})

	}

	function showInativeSearchScreen() {
		css($.searchSectionInactive, { display: 'flex' })
		css($.searchSectionActive, { display: 'none' })
		css($.empty, { display: 'none' })
	}

	function showNativeSearchScreen() {
		css($.searchSectionInactive, { display: 'none' })
		css($.searchSectionActive, { display: 'flex' })
		css($.empty, { display: 'none' })
	}


	function hideShowSections({ biografia, cronologia, novidades }, value) {
		const noHaveResults = [biografia.found, cronologia.found, novidades.found].every((v) => !!!v)

		biografia.found ? css($.biografia.section, { display: 'flex' }) : css($.biografia.section, { display: 'none' })
		cronologia.found ? css($.cronologia.section, { display: 'flex' }) : css($.cronologia.section, { display: 'none' })
		novidades.found ? css($.noticias.section, { display: 'flex' }) : css($.noticias.section, { display: 'none' })

		if (!value) {
			css($.searchSectionInactive, { display: 'flex' })
			css($.searchSectionActive, { display: 'none' })
		} else {
			css($.searchSectionInactive, { display: 'none' })
			css($.searchSectionActive, { display: 'flex' })
		}

		if (noHaveResults && value) {
			css($.empty, { display: 'flex' })
			css($.searchSectionInactive, { display: 'none' })
			css($.searchSectionActive, { display: 'none' })
		} else {
			css($.empty, { display: 'none' })
		}
	}

	/** @param {TYPESENSE_RESPONSE_DTO} data  */
	function generateHTML(data, value) {
		setQnts(data)
		setHits(data)
		hideShowSections(data, value)
	}

	async function search(e) {
		const { value } = e.target

		if (value == '') return;

		try {
			const req = await axios.get(`${url}?q=${value}`)
			/** @type {TYPESENSE_RESPONSE_DTO}  */
			const data = req.data
			if (!data) return
			generateHTML(data, value)

			console.log(data)
		} catch (error) {
			console.log(error)
			showToastify('Ocorreu um erro ao buscar')
		}
	}

	function closedModal(){
		$.input.value = ''
		$.field.classList.remove("active");
	}

	function init() {
		$.input.addEventListener('keydown',GLOBAL.debounce(search, 400))
		$.closeModal.addEventListener('click', () => {
			closedModal();
			//showInativeSearchScreen()
		})
		$.openModal.addEventListener('click', () => {
			$.field.classList.add("active");
			showNativeSearchScreen()
		})

		if (window.matchMedia('(max-width: 1180px)').matches) {
			//find('.button-search-mobile')?.appendChild($.openModal)
		}
	}

	return {
		init,
	}
}
